@import '../../variables.scss';

.container {

    h2 {
        margin-top: 50px;
        text-align: center;
    }

    hr {
        height: 1px;
        color: #666;
        background-color: #666;
        border: none;
        margin-top: 50px;
    }

    .slider {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap        ;
        .slide  {
            height : 240px;
            width : 180px;
            margin-bottom: 20px;
            img {
                height : 240px;
                width : 180px;
            }
        }
    }

    .pricingTable {
        display: block;
        margin: auto;
        width: 600px;

        table {
            h4 {
                margin: 0;
                padding: 0;
            }
            th { 
                background-color: $primaryColor;
                color : black;
                width: 33%;
            }

            tr {
                td { 
                    color: white;
                }
                td:nth-child(2) {
                    color: $textColor;
                    text-decoration: line-through;
                }
            }
        }
    }
}