@import '../../variables.scss';

.artworkGroupContainer {
    display: flex;
    flex-wrap:wrap;
    justify-content: space-between;
    
    .thumbnail  {
        position: relative;
        width: 100%;
        height:150px;
        margin-bottom: 5px;

        .mediaImage {
            height:100%;
            width:100%;
        }
        
        .title {
            background-color: rgba(0,0,0,0.7);
            position:absolute;
            bottom: 0px;
            left:0;
            right:0;
            padding:10px;
            font-size: 18px;
            color: $primaryColor;
            margin:0;
        }
    }


    @media screen and (min-width : 600px){
        display: grid;
        grid-gap: 20px;
        grid-template-columns: 49% 49%;

        .thumbnail {
            height: 300px;
        }
    }

    @media screen and (min-width : 900px){
        grid-template-columns: 32% 32% 32%;
    }

}

.bannerAd {
    height : 150px;
    width : 100%;
    background-color: beige;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position:cover;
    margin-bottom:30px;

    &:hover {
        background-color: pink;
    }

    a {
        border-radius:20px;
        background-color: darkred;
        padding: 10px 20px;
        display: inline-block;
        margin:auto;
        font-weight: bold;
        text-align: center;

        &:hover {
            background-color: rgb(72, 0, 12);
        }
    }
}