@import '../../variables.scss';

@mixin headerFooterWidth {
    width : 100%;
}

.layout {
    max-width : 1200px;
    margin: auto;

    .header { 
        padding-bottom: 20px;
        margin: auto;
        .logo { 
            text-align: center;
            display: block;
            margin: auto;
            margin-bottom: 10px;

            img { 
                width: 100%;
            }
        }

        .nav {
            display: flex;
            font-size: 1.2em;
            justify-content: space-around;
        }

        
        @media screen and (min-width : 900px){
            width:80%;

            .nav {
                font-size:1.5em;
            }

            .logo {
                width:600px;
            }
        }

    }

    .content {
        width:100%;
        box-sizing: border-box;
        
        @media screen and (min-width : 600px){
            padding:20px;
        }
    }
}



.footer {
    position: relative;
    padding-top: 30px;
    margin: auto;
    text-align : center;

    .subtitle {
        margin-bottom: 10px;
    }

    .newsletter {
        margin-bottom:30px;

        .form {
            display: flex;
            width:80%;
            margin:auto;

            input {
                padding:5px 15px;
                border-radius: 5px;
                outline: none;
                border: none;
                margin-right: 5px;
                flex-grow: 2;
            }

        }

        @media screen and (min-width : 600px){

            text-align: left;
            .form { 
                margin: 0;
            }
        }
    }


    @media screen and (min-width : 600px){
        display: grid;
        grid-template-columns: 50% 50%;
        width:80%;
    }

    .followMe {
        margin-bottom:30px;
        @media screen and (min-width : 600px){
            text-align: right;
        }
        .links {
            margin-top: 10px;

            & > *{
                margin-left: 15px;
            }
        }
    }

    .copyright {
        text-align:center;
        margin-bottom:30px;
        @media screen and (min-width : 600px){
            grid-column-start: span 2;
        }
    }
}
