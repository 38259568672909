@import "../../variables.scss";

$verticalImageWidth: 550px;
$verticalThumbnail: $verticalImageWidth / 5;
$transparentBlackBg : rgba(0,0,0,0.85);
$mobileNextPrevSize : 50px;

.blurb {
  line-height: 24px;
  letter-spacing: 1px;
}
.blurb,
.info,
.fromTheStore,
.share,
.title {
  width: 90%;
  margin: auto;
}

.title { 
  line-height:1.2em;
  padding-bottom: 20px;
}

.fromTheStore {
  h4 {
    margin-bottom: 10px;
  }

  a {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    color: white;
    font-weight: bold;

    &:hover {
      background-color: #333;
    }
  }
}

.share {
  margin-top: 30px;
  button,
  svg {
    height: 30px;
    width: 30px;
  }
}

.vertical {
  max-height: 600px;

  @media screen and (min-width: 900px) {
    float: left;
    width: $verticalImageWidth;
    min-height: 600px;
    max-height: 800px;
    margin-right: 20px;

    .image {
      min-height: 600px;
    }
  }

  .image {
    cursor: pointer;
    background-color: silver;
  }

  .carousel {
    display: grid;
    grid-template-columns: repeat(5, 20%);
    margin-bottom: 30px;

    .header {
      grid-column: span 5;
      border: none;
      margin-top: 20px;
    }
    .thumbnail:not(.header) {
      cursor: pointer;
      position: relative;
      box-sizing: border-box;
      border-right: white 1px solid;

      &:after {
        content: "";
        display: block;
        padding-bottom: 100%;
      }

      @media screen and (min-width: 900px) {
        height: $verticalThumbnail;
        width: $verticalThumbnail;
      }

      .thumbnailImage {
        position: absolute;
        background-color: silver;
        opacity: 0.5;
        width: 100%;
        height: 100%;
        @media screen and (min-width: 900px) {
          height: $verticalThumbnail;
          width: $verticalThumbnail;
        }
      }

      &:hover .thumbnailImage {
        opacity: 1;
      }

      &:last-child {
        border-right: none;
      }

      .thumbnailMore {
        width: 100%;
        position: absolute;
        color: white;
        text-align: center;

        @media screen and (min-width: 900px) {
          font-size: 30px;
          line-height: $verticalThumbnail;
          height: $verticalThumbnail;
          width: $verticalThumbnail;
        }
      }
    }
  }
}

.lightboxHeader {
  width: 500px;
  position: absolute;
  top: 0;
  right: 0;

  .logo {
    height: 80px;
    width: 400px;
    position: absolute;
    top: 20px;

    img { 
      width:400px;
    }
  }

  .close {
    position: absolute;
    top: 40px;
    right: 20px;
    font-size: 12px;
    cursor: pointer;
    color: white;
    z-index: 123;

    &:before {
      content: "x";
      cursor: pointer;
      width: 20px;
      height: 20px;
      display: block;
      line-height: 20px;
      text-align: center;
      margin: auto;
      border: solid 1px white;
      border-radius: 15px;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 5px;
    }
  }
}

.isLightbox {
  @media screen and (min-width: 900px) {
    display: flex;
    width: 100%;

    .vertical {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 500px;
      left: 0px;
      max-width: none;
      max-height: none;
      width: auto !important;

      .image {
        background-color: black;
        top: 0;
        bottom: 0;
        right: 0px;
        left: 0px;
        position: absolute;
      }
      .carousel {
        display: none;
      }

      .next,
      .previous {
        background-color: $transparentBlackBg;
        width: 50px;
        height: 50px;
        line-height: 50px;
        z-index: 123;
        top: 50%;
        position: absolute;
        color: white;
        text-align: center;
        &:before {
          cursor: pointer;
          line-height: 50px;
          width: 50px;
          height: 50px;
          display: block;
          text-align: center;
          margin: auto;
          border-radius: 15px;
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 5px;
        }
      }

      .next {
        right: 0px;
        &:before {
          content: ">";
        }
      }

      .previous {
        left: 0px;
        &:before {
          content: "<";
        }
      }
    }

    .sidebar {
      margin-top: 130px;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 500px;
    }
  }
}

body { 
  background-color: black;
}
.mobile {

  .mobileImage { 
    height:0;
    overflow: visible; 

    
    .image { 
      position:fixed;
      top:100px;
      bottom:100px;
      left:10px;
      right:10px;
      z-index: 1;
  
    }
  }
  .mobileContent { 
    position: relative;
    margin-top:200%;
    z-index: 123;
    background-color: $transparentBlackBg;
    padding-bottom:20px;
  }

  .info {
    display:none;
  }

  .title {
    background-color: $transparentBlackBg;
    position: absolute;
    transform: translateY(-100%);
    padding:20px;
    font-size:1.5em;
    box-sizing: border-box;
    width:100%;
  }

  .logo, .logo img {
    background-color: $transparentBlackBg;
    width:100%;
    position: absolute;
    top:0;
    z-index: 1234;
  }

  .footer { 
    position: relative;
    background-color:rgba(0,0,0,0.85);
    padding-bottom: 20px;
    z-index: 123;
  }


  .next {
    position: relative;
    background-color:rgba(0,0,0,0.6);
    border-radius:$mobileNextPrevSize;
    width:$mobileNextPrevSize;
    height:$mobileNextPrevSize;
    position: fixed;
    z-index: 123;
    right: -($mobileNextPrevSize/2);
    top:50%;
    line-height:$mobileNextPrevSize;
    &:before {
      position: absolute;
      content: ">";
      left:12px;
    }
  }

  .previous {
    position: relative;
    background-color:rgba(0,0,0,0.6);
    border-radius:$mobileNextPrevSize;
    width:$mobileNextPrevSize;
    height:$mobileNextPrevSize;
    position: fixed;
    z-index: 123;
    left: -($mobileNextPrevSize/2);
    top:50%;
    line-height:$mobileNextPrevSize;
    &:before {
      position: absolute;
      content: "<";
      right:12px;
    }
  }
}